import Application from "../app"
import toast, { Toaster } from 'react-hot-toast';

const App = () => {
  return <div>
    <Application />
    <Toaster />
    </div>
}

export default App
